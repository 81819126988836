<template>
    <div class="fill-height">
        <v-container>
            <auth-header title="Login"/>
            <v-form ref="form" class="auth-form mt-8">
                <v-text-field label="Email or Username"
                            solo
                            :background-color="background"
                            :error-messages="errors.email"
                            v-model="email"
                            :rules="emailRules"
                />
                <v-text-field label="Password"
                            solo
                            :background-color="background"
                            type="password"
                            :error-messages="errors.password"
                            v-model="password"
                            v-on:keyup.enter="handleLogin"
                            :rules="passwordRules"
                />
                <div class="text-right">
                    <router-link to="/forget-password" class="link">
                        <span class="text-caption">Forget Password?</span>
                    </router-link>
                </div>
                <turnstile-captcha explicit-render @verified="captchaToken = $event" />
                <span v-if="errors.captcha_token" class="red--text"><strong>* </strong>
                    {{ errors.captcha_token[0] }}
                </span>

                <btn-large class="btn-accent mt-8"
                           @click="handleLogin" :loading="isLoading"
                           text="Login"/>
                <div class="text-caption text-center mt-8">
                    <span>You don’t have an account?&nbsp;</span>
                    <router-link to="/sign-up"
                                 class="link error--text">
                        <span>Sign up</span>
                    </router-link>
                </div>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import AuthHeader from '@/components/app/auth/AuthHeader';
import TextField from '@/components/app/form/TextField';
import {mapActions, mapMutations, mapState} from "vuex";
import TurnstileCaptcha from '@/components/app/auth/TurnstileCaptcha';

export default {
    name: "Login",
    components: {
        AuthHeader,
        TextField,
        TurnstileCaptcha,
    },
    data: () => ({
        email: '',
        password: '',
        errors: {
            email: '',
            password: ''
        },
        emailRules: [
            (v) => !!v || 'Email Or Username is required',
        ],
        passwordRules: [
            (v) => !!v || 'Password is required',
        ],
        background: 'rgba(237,237,237, .1)',
        isLoading: false,
        captchaToken: null,
    }),
    computed: {
        ...mapState({
            user: state => state.user.user.data,
        })
    },
    methods: {
        handleLogin() {
            this.isLoading = false;
            if (!this.$refs.form.validate()) {
                this.isLoading = false;
                return;
            }
            if (!this.captchaToken) {
                alert("Please complete the CAPTCHA");
                return;
            }
            const {email, password, captchaToken} = this;
            this.$auth.login({
                data: {
                    email,
                    password,
                    captchaToken
                },
                staySignedIn: true,
                fetchUser: true
            })
                .then((response) => {
                    this.isLoading = false;
                    localStorage.setItem('email_verified', response.data.data.email_verified)
                    if (!response.data.data.email_verified && this.$router.currentRoute.name !== 'email-verify') {
                        this.$router.push({'name': 'email-verify'}).catch((e) => console.log(e))
                    } else if (this.$router.currentRoute.name !== 'auth.home') {
                        this.$router.push({'name': 'auth.home'}).catch((e) => console.log(e))
                    }
                }, e => {
                    this.isLoading = false;
                    this.errors = e.response.data.errors
                })
        },

        ...mapActions([
            'login',
        ]),
        ...mapMutations(['showSnackBar'])
    }
}
</script>
